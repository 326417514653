@import '../../assets/scss/GlobalScssVariables.scss';

.LogoContainer {
  display: flex;
  align-items: center;
}

.TitleContainer {
  display: flex;
  flex-direction: column;
}

.Title {
  font-size: 1rem;
  font-weight: bold;
  white-space: nowrap;
  &--light {
    @extend .Title;
    color: $light-blue;
  }
  &--dark {
    @extend .Title;
    color: $light-blue;
  }
}
