@import '../../assets/scss/GlobalScssVariables.scss';

.TruckContainer {
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 260px;
  margin-left: 15px;
  margin-top: 15px;
  cursor: pointer;
}

.TrailerWall {
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  flex: 1;
  background-color: #e6e6e6;
  border-left: 2px solid $light-brighter-gray;
  border-right: 2px solid $light-brighter-gray;
  border-top: 2px solid $light-brighter-gray;
}

.Trailer {
  margin-left: 4px;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $light-white;
  border-left: 2px solid $light-brighter-gray;
  border-right: 2px solid $light-brighter-gray;
}

.TrailerTopSection {
  background-color: #e6e6e6;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TrailerBottomSection {
  padding: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}

.Floor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  background-color: $light-brighter-gray;
  border-radius: 3px;
}

.Priority {
  background-color: $light-red;
  border-radius: 4px;
  padding-bottom: 2px;
  padding-top: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 4px;
}

.Priority>span {
  color: $light-white;
}

.Wheel {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #999999;
  width: 35px;
  height: 23px;
}

.Wheels {
  display: flex;
}

.LeftWheels {
  display: flex;
  margin-right: auto;
  margin-left: 5px;
}

.RightWheels {
  display: flex;
  margin-left: auto;
  margin-right: 5px;
}

.NumberPlate {
  height: 24px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  min-width: 65px;
}

.NumberPlate span {
  font-size: 0.75rem;
  font-weight: bold;
}

.Lights {
  display: flex;
  height: 15px;
}

.WhiteLight {
  width: 4px;
  height: 100%;
  background-color: $light-gray-white;
}

.RedBigLight {
  width: 10px;
  height: 100%;
  background-color: $light-red;
}

.RedLight {
  width: 4px;
  height: 100%;
  background-color: $light-red;
}

.YellowLight {
  width: 8px;
  height: 100%;
  background-color: $light-orange;
}

.TransportId {
  margin-left: 4px;
}

.SectionContainer {
  background-color: #ffffff;
  margin: 0 auto;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(66, 68, 90, 0.52);
  -moz-box-shadow: 0px 0px 2px 0px rgba(66, 68, 90, 0.52);
  box-shadow: 0px 0px 2px 0px rgba(66, 68, 90, 0.52);
  padding: 2em 3em;
  margin-bottom: 20px;
}

.LabelWithInput {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.4em;
}

.SectionTitle {
  padding-bottom: 1em;
  padding-top: 1.5em;
  font-size: 18px;
  display: block;
  color: #245d8d;
  font-weight: 500;
}