@import '../../assets/scss/GlobalScssVariables.scss';

.HeaderContainer {
  display: flex;
  position: fixed;
  z-index: $z-index-header;
  height: $header-height;
  width: 100%;
  align-items: center;

  &--light {
    @extend .HeaderContainer;
    background-color: $light-header-color;
    box-shadow: 0 0 8px 5px rgba(#000000, 0.1);
    // clip-path: polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%);
  }

  &--dark {
    @extend .HeaderContainer;
    background-color: $dark-header-color;
  }
}

.ThemeSwitch {
  margin-left: auto;
  margin-right: 10px;
}

@media only screen and (max-width: $mobile-width) {
  .ThemeSwitch {
    display: none !important;
  }

  .UserInfo {
    display: none;
  }
}