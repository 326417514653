.SchedulerContainer {
  overflow: hidden;
  position: relative;
  z-index: 100;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.HourTick {
  border-left: 1px solid rgba(black, 0.1);
  position: absolute;
  top: 36px;
  bottom: 0;
  pointer-events: none;
  z-index: 100;
}

.HourTickLabel {
  position: absolute;
  top: 15px;
  margin-left: 6px;
  font-weight: bold;
  user-select: none;
}

.DayTick {
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 100;
}

.DayTickLabel {
  position: absolute;
  top: 9px;
  text-align: center;
  font-weight: bold;
  user-select: none;
}

.HeaderDayRow {
  min-height: 36px;
  border-bottom: 1px solid #ddd;
}

.HeaderHourRow {
  min-height: 36px;
  display: block;
  position: relative;
}

.Content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.SchedulerLines {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
