.switchAndUserinfoContainer {
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.themeSwitch {
  margin-right: 10%;
}
