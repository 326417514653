@import './assets/scss/GlobalScssVariables.scss';
@import 'react-toastify/dist/ReactToastify.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-width: 400px;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  list-style-type: none;
  padding-inline-start: 0;
}

h1 {
  margin: 0;
  line-height: 1.1;
}

h4 {
  margin: 0;
}

hr {
  border: 0;
  clear: both;
  display: block;
  width: 90%;
  background-color: $light-brighter-gray;
  height: 1px;
}

.MuiOutlinedInput-root {
  border-radius: 0 !important;
  height: 37px !important;
}
