@import '../../assets/scss/GlobalScssVariables.scss';

.LinkList {
  &--desktop {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &--desktop li {
    margin-left: 15px;
  }
  &--mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20%;
  }
  &--mobile li {
    padding-top: 20px;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.Link {
  padding: 3% 7%;
  font-size: 1.125rem;
  white-space: nowrap;
  text-align: center;
  word-break: normal;
  font-weight: bold;
  display: flex;
  align-items: center;
  &--light {
    @extend .Link;
    color: $light-gray;
    &--active {
      @extend .Link--light;
      color: $light-blue;
    }
    &:not(.Link--light--active):hover {
      color: $light-brighter-blue;
    }
  }
  &__Label {
    margin-left: 5px;
  }
  // &--dark {
  // @extend .Link;
  // color: $white-dark;
  // &--active {
  //   @extend .Link--dark;
  //   border-bottom: 2px solid $blue-dark;
  //   color: $blue-dark;
  // }
  // &:not(.Link--dark--active):hover {
  //   color:
  // }
  // }
}

.SvgColor :global svg {
  fill: $light-gray;
}

.SvgColor--active :global svg {
  fill: $light-blue;
}

.SvgColor:not(.SvgColor--active):hover :global svg {
  fill: $light-brighter-blue;
}

@media only screen and (min-width: $mobile-width) and (max-width: $tablet-width) {
  .SvgColor :global svg {
    display: none;
  }
}

@media only screen and (max-width: $mobile-width) {
  .LinkList {
    &--desktop {
      display: none;
    }
  }
}
