/* Colors For Light Theme */
$light-blue: #175386;
$light-brighter-blue: #6894cd;
$light-header-color: #ffffff;
$light-white: #ffffff;
$light-black: #000000;
$light-gray: #6e7888;
$light-brighter-gray: #b3b3b3;
$light-gray-white: #faf8ff;
$light-red: #cc3300;
$light-orange: #dda302;
$light-green: #008a5f;

/* Color For Dark Theme */
$dark-white: #f7f7f7;
$dark-header-color: #17212f;

/* To Handle Responsive */
$header-height: 60px;
$tablet-width: 1000px;
$mobile-width: 600px; //When you change that value, change also width in Enums.ts

/* To Handle z-index */
$z-index-header: 999;
$z-select-popover: 9999;
