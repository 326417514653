@import './assets/scss/GlobalScssVariables.scss';

.MainContainer {
  display: flex;
  flex-direction: column;
}

.PageContainer {
  display: flex;
  margin-top: $header-height;
  max-height: calc(100vh - #{$header-height});
}
.OverflowControl {
  display: flex;
  flex: 1;
  min-height: calc(100vh - #{$header-height});
  overflow-y: auto;
}
form {
  padding: 8px;
}
