@import '../../../assets/scss/GlobalScssVariables.scss';

.SchedulerLine {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 65px;
  border-top: 1px dotted rgba(0, 0, 0, 0.07);
  border-bottom: 1px dotted rgba(0, 0, 0, 0.07);
  margin-bottom: 4px;
  background-color: #eef1f4;
  user-select: none;
}

.SchedulerOnlyOneLine {
  @extend .SchedulerLine;
  flex-grow: 1;
  overflow: hidden auto;
}

.SchedulerOnlyOneLine:last-child {
  margin-bottom: 0;
}

.SchedulerLine:not(.SchedulerOnlyOneLine):hover {
  background-color: #dfeef5;
}

.DriverNameLineGroup {
  position: relative;
  background-color: $light-blue;
  color: #fff;
  margin: 3px 0;
  z-index: 1000;
  font-weight: bold;
  box-shadow: 0 0 1px rgba(67, 90, 111, 0.3),
    0 2px 4px -2px rgba(67, 90, 111, 0.45);
  font-size: 10px;
  padding: 0 6px 0 4px;
  line-height: 1.2;
  writing-mode: vertical-lr;
  text-align: center;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
