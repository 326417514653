@import '../../assets/scss/GlobalScssVariables.scss';

.TooltipContent {
  min-width: 260px;
  display: flex;
  flex-direction: column;
}

.Header {
  height: 24px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-left: -3px;
  margin-right: -3px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  font-size: 1rem;
  &--planned {
    @extend .Header;
    background-color: $light-blue;
    color: $light-white;
  }
  &--delayed {
    @extend .Header;
    background-color: $light-red;
    color: $light-white;
  }
  &--inProgress {
    @extend .Header;
    background: repeating-linear-gradient(
      -45deg,
      #ffbb0c 0,
      #ffbb0c 25px,
      #f7b200 25px,
      #f7b200 50px
    );
    background-size: 150% 100%;
    animation: loading-progress 2s linear infinite;
    color: #000000;
  }
  &--finished {
    @extend .Header;
    background-color: $light-green;
    color: $light-white;
  }
}

@keyframes loading-progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -70px 0;
  }
}

.Label {
  color: $light-gray;
}

.Detail {
  font-size: 0.875rem;
}
