@import '../assets/scss/GlobalScssVariables.scss';

.TrucksContainer {
  display: flex;
  flex-wrap: wrap;
}

.TransportTopSection {
  display: flex;
  align-items: center;
}

.TransportTopLabel {
  padding: 20px;
  font-weight: bold;
  font-size: 1.5rem;
  max-width: 250px;
}

.Priority {
  display: flex;
  background-color: $light-red;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 2px;
  width: 15px;
}

.Priority > span {
  color: $light-white;
  font-size: 1.5rem;
  line-height: 1.1;
}

// .TransportTopLabel {
//   position: fixed;
//   top: 60px;
// }
