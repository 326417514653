.ColumnPlaceAndTime {
  min-width: 30%;
}

.ColumnDriver {
  width: 180px;
}

.TransportInfo {
  display: flex;
  flex-direction: column;
}

.Date {
  font-size: 0.875rem;
}

.Company {
  margin-top: 4px;
  font-size: 1rem;
  font-weight: bold;
}

.Location {
  font-size: 0.875rem;
}
