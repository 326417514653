@import '../../assets/scss/GlobalScssVariables.scss';

.CalendarContainer {
  display: inline-flex;
  margin-right: 16px;
  height: 35px;
}

.Day {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-width: 53px;
  padding: 8px 8px 8px;
  text-align: center;
  border: 1px solid #ddd;
  margin-left: -1px;
  line-height: 1;
  position: relative;
  cursor: pointer;
  &--sunday {
    color: mix($light-blue, $light-red, 5);
  }

  &--selected {
    box-shadow: inset 0 0 0 2px #cccccc;
    background-color: #e6e6e6;
  }

  &:hover {
    background-color: #e6e6e6;
  }

  &--today {
    box-shadow: inset 0 0 0 2px #bf0707;
  }
}

.Day strong {
  display: block;
  font-weight: bold;
  font-size: 17px;
  position: absolute;
  left: 5px;
  top: 8px;
  font-size: 20px;
}
.Day small {
  display: block;
  font-size: 10px;
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-weight: 500;
}
.Day small:first-child {
  font-size: 9px;
  line-height: 8px;
  padding-bottom: 3px;
  top: 5px;
}
