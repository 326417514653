@import '../../assets/scss/GlobalScssVariables.scss';

.Icon {
  fill: $light-blue;
  transition: background-color 0.3s ease;
  &--active {
    transition: background-color 0.3s ease;
    fill: #ffffff;
  }
}
