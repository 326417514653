@import '../../../assets/scss/GlobalScssVariables.scss';

.TransportEntry {
  position: absolute;
  top: 0;
  height: 38px;
  margin: 5px 0;
  border-radius: 4px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 100;
  padding: 3px 2px;
  user-select: none;
  transition: top 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  &--planned {
    @extend .TransportEntry;
    background-color: rgba($light-blue, 0.8);
    border: solid $light-blue;
    border-width: 1px 4px;
    color: rgba(255, 255, 255, 0.9);
  }
  &--delayed {
    @extend .TransportEntry;
    background-color: rgba($light-red, 0.8);
    border: solid $light-red;
    border-width: 1px 4px;
    color: rgba(255, 255, 255, 0.9);
  }
  &--inProgress {
    @extend .TransportEntry;
    border: solid #fcc913;
    background: repeating-linear-gradient(
      -45deg,
      rgba(#fde078, 0.8) 0,
      rgba(#fde078, 0.8) 25px,
      rgba(#fcc913, 0.8) 25px,
      rgba(#fcc913, 0.8) 50px
    );
    background-size: 150% 100%;
    border-width: 1px 4px;
    animation: loading-progress 2s linear infinite;
  }
  &--finished {
    @extend .TransportEntry;
    background-color: rgba($light-green, 0.8);
    border: solid $light-green;
    border-width: 1px 4px;
    color: rgba(255, 255, 255, 0.9);
  }
}

@keyframes loading-progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -70px 0;
  }
}

.TransportEntry:hover {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  z-index: 200;
  animation: none;
}

.TransportEntry:hover .TimeIndicator {
  cursor: pointer;
  opacity: 0.8;
}

.TransportEntry--dimmed {
  opacity: 0.2;
}

.TransportEntry--dimmed:hover {
  opacity: 0.7;
}

.TimeIndicator {
  position: absolute;
  background-color: #001024;
  color: #fff;
  padding: 2px 4px;
  border-radius: 3px;
  font-style: normal;
  font-size: 10px;
  top: -24px;
  opacity: 0;
  transition: opacity 0.25s ease-in-out 0.1s;

  &:after {
    position: absolute;
    content: ' ';
    border: 6px solid #001024;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent;
    left: calc(50% - 6px);
    bottom: -3px;
  }

  &--start {
    @extend .TimeIndicator;
    left: -20px;
  }
  &--end {
    @extend .TimeIndicator;
    right: -22px;
  }
}

.Inner {
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1;
}

.TransportId {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  align-items: center;
}

.TransportId strong {
  overflow: hidden;
}

.Priority {
  background-color: $light-red;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 2px;
}

.Priority > span {
  color: $light-white;
  font-size: 13px;
  line-height: 1.1;
}
