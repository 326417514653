@import '../../assets/scss/GlobalScssVariables.scss';

.TableContainer {
  flex: 1;
  background: repeating-linear-gradient(
    -45deg,
    #e5e9ee 0,
    #e5e9ee 15px,
    #eef1f4 15px,
    #eef1f4 30px
  );
  overflow: auto;
}

.Table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.Table tr:not(.Empty) {
  background-color: #fff;
  border-bottom: 2px solid #ccc;
}

.Empty {
  background-color: transparent;
  height: 222px;
}

.Table td {
  padding: 9px 12px 8px;
}

.Table thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
  font-weight: bold;
  color: #175386;
  height: 57px;
  z-index: 100;
  border-top: 0 none;
  vertical-align: middle;
  border-bottom: 2px solid #ccc;
  text-align: left;
}

.DateHeader {
  border-bottom: 2px solid #ccc;
}

.DateHeader th {
  position: sticky;
  top: 57px;
  height: 36px;
  z-index: 100;
  background-color: #eef1f4;
  font-weight: bold;
  font-size: 16px;
  border-top: 0 none;
  border-bottom: 1px solid #d9d9d9;
  vertical-align: middle;
  padding-top: 0;
  padding-bottom: 2px;
  text-align: center;
}

.ColumnId {
  width: 100px;
  white-space: nowrap;
  padding-left: 14px;
}

.ColumnStatus {
  width: 76px;
  border-radius: 6px;
  vertical-align: middle;
  text-align: center;
  &--planned {
    @extend .ColumnStatus;
    background-color: $light-blue;
  }
  &--delayed {
    @extend .ColumnStatus;
    background-color: $light-red;
  }
  &--inProgress {
    @extend .ColumnStatus;
    background-color: $light-orange;
  }
  &--finished {
    @extend .ColumnStatus;
    background-color: $light-green;
  }
}

.Table tr:not(.DateHeader):not(.Empty):not(thead tr):hover {
  background-color: #e7edf2;
  cursor: pointer;
  animation: none;
}

.TableRow {
  // &--planned {
  //   @extend .TableRow;
  // }
  // &--delayed {
  //   @extend .TableRow;
  // }
  &--inProgress {
    // @extend .TableRow;
    background: repeating-linear-gradient(
      -45deg,
      #ffbb0c 0,
      #ffbb0c 25px,
      #f7b200 25px,
      #f7b200 50px
    );
    background-size: 150% 100%;
    animation: loading-progress 2s linear infinite;
  }
  // &--finished {
  //   @extend .TableRow;
  // }
}

@keyframes loading-progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -70px 0;
  }
}

.DelayedIcon {
  font-size: 26px;
  font-weight: 700;
  color: white;
}

.Priority {
  background-color: $light-red;
  border-radius: 4px;
  padding-bottom: 2px;
  padding-top: 2px;
  padding-left: 4px;
  padding-right: 4px;
  color: #ffffff;
  width: 10px;
}
