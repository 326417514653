@import '../../../assets/scss/GlobalScssVariables.scss';

.CurrentTimeIndicator {
  position: absolute;
  top: 26px;
  bottom: 0;
  z-index: 999;
  pointer-events: none;
}

.CurrentTimeIndicator label {
  background-color: $light-red;
  display: inline-block;
  padding: 2px 6px;
  border-radius: 3px;
  color: white;
  font-size: 11px;
  margin-left: calc(-50% + 2px);
  text-align: center;
}

.Line {
  border-left: 3px dashed $light-red;
  height: 100%;
}
