@import '../../assets/scss/GlobalScssVariables.scss';

.Name {
  font-size: 1rem;
  font-weight: bold;
  padding-right: 10px;
  &--light {
    @extend .Name;
    color: $light-black;
  }
  &--dark {
    @extend .Name;
    color: $dark-white;
  }
}
