.SectionContainer:not(first-child) label {
  margin-top: 5px;
}

.SectionContainer label {
  margin-bottom: 2px;
}

.DrivingLicenseDetails {
  display: flex;
}

.DrivingLicenseCategory {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
}

.DrivingLicenseCategory>div {
  width: 50px;
}

.DrivingLicenseExpiryDate {
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ContactPersonsDetails {
  display: flex;
}

.ContactPerson {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.SectionContainer {
  background-color: #ffffff;
  margin: 0 auto;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(66, 68, 90, 0.52);
  -moz-box-shadow: 0px 0px 2px 0px rgba(66, 68, 90, 0.52);
  box-shadow: 0px 0px 2px 0px rgba(66, 68, 90, 0.52);
  padding: 2em 3em;
  margin-bottom: 20px;
  width: 70%;
}

.LabelWithInput {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.4em;
}

.SectionTitle {
  padding-bottom: 1em;
  padding-top: 1.5em;
  font-size: 18px;
  display: block;
  color: #245d8d;
  font-weight: 500;
  margin: 0 auto;
  width: calc(70% + 5em);
}