.SectionContainer {
  display: flex;
  flex-direction: column;
  width: 280px;
  margin-left: 32px;
  margin-top: 15px;
}

.SectionContainer:not(first-child) label {
  margin-top: 5px;
}

.SectionContainer label {
  margin-bottom: 2px;
}

.LabelWithInput {
  display: flex;
  flex-direction: column;
}

.DrivingLicenseDetails {
  display: flex;
}

.DrivingLicenseCategory {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
}

.DrivingLicenseCategory > div {
  width: 50px;
}

.DrivingLicenseExpiryDate {
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ContactPersonsDetails {
  display: flex;
}

.ContactPerson {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.SectionTitle {
  font-weight: bold;
  font-size: 1.25rem;
}
