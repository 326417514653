@import '../../assets/scss/GlobalScssVariables.scss';

.FiterBarContainer {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
  flex-wrap: wrap;
}

.ButtonsContainer {
  display: flex;
  margin-right: 16px;
}

.IconButton {
  background: none;
  color: $light-blue;
  padding: 5px;
  margin: 5px;
  border: 2px solid $light-blue;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &--active {
    background-color: #175386;
  }
}

.IconButton:not(.IconButton--active):hover {
  background-color: #2482d2;
}

.IconButton:disabled {
  background-color: #bfeaf9;
  cursor: default;
}
