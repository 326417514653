@import '../../assets/scss/GlobalScssVariables.scss';

.SelectContainer {
  border: 1px solid #ccc;
  height: 35px;
  line-height: 35px;
  padding-left: 10px;
  padding-right: 36px;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  min-width: 120px;
}

.SelectContainer:after {
  top: 14px;
  right: 10px;
  position: absolute;
  content: ' ';
  border: 6px solid transparent;
  border-top-color: #7f7f7f;
}

.Placeholder {
  color: #c0c0c0;
}

.PopOverContent {
  opacity: 1;
  transition: 0.3s cubic-bezier(0.54, 1.12, 0.38, 1.11);
  transition-property: opacity, transform;
  margin-top: 14px;
  transform-origin: top;
  position: relative;
  z-index: 9999;
  margin: 0;
  box-shadow: 0 0 0 1px rgba(#000000, 0.01), 0 2px 16px rgba(#000000, 0.01);
  border-radius: 6px;
  backface-visibility: hidden;
  min-height: 300px;
  max-height: 400px;
}

.SearchContent {
  min-height: 360px;
  background-color: #fff;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-items: center;
  border-radius: 6px;
  text-align: left;
}

.Arrow {
  top: -7px;
  margin-left: 14px;
  position: absolute;
  transform: translateX(-50%) rotate(45deg);
  height: 14px;
  width: 14px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(#000000, 0.01), 0 2px 16px rgba(#000000, 0.01);
  z-index: -1;
  left: 100px;
}

.ItemResult {
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  &--clear {
    @extend .ItemResult;
    font-style: italic;
    color: #7f7f7f;
    justify-content: center;
    font-weight: bold;
  }
  &--active {
    @extend .ItemResult;
    background-color: #7fd5f3;
  }
}

.TimePicker > div {
  border-radius: 0 !important;
}

.DateTimeContainer {
  display: flex;
}
